<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Order ID
          </th>
          <th class="text-left">
            Customer
          </th>
          <th class="text-left">
            Time
          </th>
          <th class="text-left">
            Date
          </th>
          <th class="text-left">
            Passengers
          </th>
          <th class="text-left">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in orders"
          :key="item.name"
        >
          <td>{{ item.customId }}</td>
          <td>{{ item.customerFullName }}</td>
          <td>{{ item.orderDateTime | fromUnixTime }}</td>
          <td>{{ item.orderDateTime | fromUnix }}</td>
          <td>{{ item.passengers }}</td>
          <td>
            <v-chip v-if="item.orderStatus == 'Pending'" color="primary" class="px-5">
              {{ item.orderStatus }}
            </v-chip>
            <v-chip v-else-if="item.orderStatus == 'Confirmed'" color="success" class="px-5">
              {{ item.orderStatus }}
            </v-chip>
            <v-chip v-else color="error" class="px-5">
              {{ item.orderStatus }}
            </v-chip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  props: {
    orders: {
      type: Array,
      required: true
    }
  },

  created() {
    console.log(this.orders)
  }

}
</script>

<style>

</style>