<template>
  <v-sheet class="pa-5" elevation="1">
    <h1 class="display-1">Dashboard</h1>
    <v-divider class="mt-3"></v-divider>
    <v-row class="mt-5">
      <v-col cols="12" md="4" v-for="item in statsNumbers" :key="item.title">
        <number-card :isCurrency="isCurrency" :numberData="item" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Recent Activities</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <event-timeline :timeline="timeline" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Orders</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <order-trip-table :orders="orders" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import NumberCard from "@/components/shared/NumberCard.vue";
import OrderTripTable from '@/components/shared/OrderTripTable.vue';
import EventTimeline from '../../components/dashboard/EventTimeline.vue';
export default {
  components: { NumberCard, OrderTripTable, EventTimeline },

  data: () => ({
    isCurrency: false,
    orders: [
      {
        customId: '1',
        customerFullName: 'something',
        timeStamp: new Date().getTime(),
        passengers: 6,
        orderStatus: 'Pending'
      }
    ],
    timeline: [
      {
        color: 'red lighten-2',
        icon: 'mdi-alert',
        title: 'New Order',
        text: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at.'
      },
      {
        color: 'green lighten-1',
        icon: 'mdi-check',
        title: 'New Van Created',
        text: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at.'
      }
    ],
    statsNumbers: [
      {
        title: "Drivers",
        number: 2,
      },
      {
        title: "Vans",
        number: 2,
      },
      {
        title: "Orders",
        number: 2,
      },
    ],
  }),
};
</script>

<style></style>
