<template>
  <v-card class="d-flex justify-center align-end pb-5 pr-5" height="150">
    <div class="display-4 text-number">{{ numberData.number }}</div>
    <v-icon x-large class="mb-2" v-if="isCurrency">mdi-currency-eur</v-icon>
    <div class="display-1 pb-2 primary--text ">{{ numberData.title }}</div>
  </v-card>
</template>

<script>
export default {

  props: {
    isCurrency: {
      type: Boolean,
      required: false
    },
    numberData: {
      type: Object,
      required: true
    }

  }
}

</script>

<style></style>
